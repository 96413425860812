var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "sidebar" },
    [
      _c("li", { staticClass: "sidebar__item sidebar__logo" }, [
        _c(
          "a",
          { attrs: { href: "https://thecharts.com" } },
          [
            _c("icon", {
              attrs: { name: "logo", width: "150", height: "60", original: "" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Dashboard" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "home" },
              }),
              _vm._v("Dashboard"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Payouts" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "money-bag" },
              }),
              _vm._v("Payouts"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "NewUploads", params: { pageIndex: 1 } } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "beats" },
              }),
              _vm._v("New Uploads"),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }