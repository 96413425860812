var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar" }, [
    _vm.showMobileMenu
      ? _c(
          "a",
          { staticClass: "navbar__close", on: { click: _vm.toggleMobileMenu } },
          [_c("icon", { attrs: { name: "close" } })],
          1
        )
      : _vm._e(),
    _c("ul", { staticClass: "navbar--desktop navbar__items" }, [
      _vm.authenticatedUser
        ? _c(
            "li",
            { staticClass: "navbar__item navbar__item--right user-dropdown" },
            [
              _c(
                "a",
                { staticClass: "flexbox" },
                [
                  _c("icon", {
                    staticClass: "user-dropdown__user-img",
                    attrs: { name: "user-alt" },
                  }),
                  _vm._m(0),
                  _c("icon", {
                    staticStyle: { "margin-left": "5px" },
                    attrs: {
                      name: "arrow-down",
                      width: "14px",
                      height: "14px",
                      color: "#DCDCDC",
                    },
                  }),
                ],
                1
              ),
              _c("ul", { staticClass: "user-dropdown__menu" }, [
                _c("li", { staticClass: "user-dropdown__item" }, [
                  _c("a", { on: { click: _vm.finalLogout } }, [
                    _vm._v("Log Out"),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _c("ul", { staticClass: "navbar--mobile navbar__items" }, [
      _c(
        "li",
        { staticClass: "navbar__item navbar__logo" },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "Home" } } },
            [
              _c("icon", {
                staticClass: "navbar__logo-icon",
                attrs: {
                  name: "logo",
                  width: "148",
                  height: "32",
                  original: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("li", { staticClass: "navbar__item navbar__item--right" }, [
        _c(
          "a",
          { on: { click: _vm.toggleMobileMenu } },
          [_c("icon", { attrs: { name: "hamburger" } })],
          1
        ),
      ]),
    ]),
    _c(
      "ul",
      {
        staticClass: "navbar--mobile navbar__panel-mobile navbar__items",
        class: { "navbar__panel-mobile--open": _vm.showMobileMenu },
      },
      [
        _c(
          "li",
          { staticClass: "navbar__item", on: { click: _vm.toggleMobileMenu } },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Dashboard" } } },
              [
                _c("icon", {
                  staticClass: "sidebar__icon",
                  attrs: { name: "home" },
                }),
                _vm._v("Dashboard"),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "navbar__item", on: { click: _vm.toggleMobileMenu } },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "Payouts" } } },
              [
                _c("icon", {
                  staticClass: "sidebar__icon",
                  attrs: { name: "money-bag" },
                }),
                _vm._v("Payouts"),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "navbar__item", on: { click: _vm.toggleMobileMenu } },
          [
            _c(
              "router-link",
              {
                attrs: { to: { name: "NewUploads", params: { pageIndex: 1 } } },
              },
              [
                _c("icon", {
                  staticClass: "sidebar__icon",
                  attrs: { name: "beats" },
                }),
                _vm._v("New Uploads"),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          {
            staticClass: "navbar__item",
            staticStyle: { "margin-bottom": "40px" },
            on: {
              click: function ($event) {
                return _vm.toggleMobileMenu(null)
              },
            },
          },
          [_c("a", { on: { click: _vm.finalLogout } }, [_vm._v("Log Out")])]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(_vm._s(_vm.authenticatedUser.first_name || "Admin")),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }