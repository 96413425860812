/* eslint-disable */
import './arrow-down'
import './arrow-left'
import './arrow-right'
import './beats'
import './close'
import './hamburger'
import './home'
import './logo-icon'
import './logo'
import './money-bag'
import './next'
import './pause'
import './play'
import './prev'
import './user-alt'
