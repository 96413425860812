var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixedBottomElements" }, [
    _vm.playerIsShowing && _vm.beatPlaying
      ? _c("div", { staticClass: "player" }, [
          _c("div", { staticClass: "player__details" }, [
            _c("img", {
              staticClass: "player__trackImage",
              attrs: {
                src: _vm.beat.image_url
                  ? _vm.beat.image_url
                  : "./img/picture.svg",
              },
            }),
            _c("div", { staticClass: "player__trackTitleWrapper" }, [
              _c(
                "div",
                { staticClass: "player__trackTitle" },
                [
                  _c("p", { attrs: { title: _vm.beat.name } }, [
                    _vm._v(_vm._s(_vm.beat.name)),
                  ]),
                  _c("icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.beat.sampled,
                        expression: "beat.sampled",
                      },
                    ],
                    staticClass: "player__iconCopyright",
                    attrs: { name: "copyright" },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass:
                    "player__trackAuthor player__trackAuthor--isLink",
                  attrs: {
                    to: {
                      name: "Producer",
                      params: { slug: _vm.beat.producer.slug },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.beat.producer.display_name))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "player__controls" }, [
            _c(
              "div",
              { staticClass: "player__controlsRow" },
              [
                _c("icon", {
                  staticClass: "player__iconPrev",
                  attrs: { name: "prev" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.playPrevious.apply(null, arguments)
                    },
                  },
                }),
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.audioLoading,
                      expression: "!audioLoading",
                    },
                  ],
                  staticClass: "player__iconPlayPause",
                  attrs: { name: _vm.isAudioPaused ? "play" : "pause" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.TOGGLE_PLAY_STATUS.apply(null, arguments)
                    },
                  },
                }),
                _c("the-player-loader", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.audioLoading,
                      expression: "audioLoading",
                    },
                  ],
                  staticClass: "player__iconLoad",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.TOGGLE_PLAY_STATUS.apply(null, arguments)
                    },
                  },
                }),
                _c("icon", {
                  staticClass: "player__iconNext",
                  attrs: { name: "next" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.playNext.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "player__controlsRow player__controlsRow--topOnMobile",
              },
              [
                _c("div", { staticClass: "player__timeDisplay" }, [
                  _vm._v(_vm._s(_vm._f("time")(_vm.currentPosition))),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "player__timeBar",
                    on: {
                      mousedown: _vm.startDrag,
                      mouseup: function ($event) {
                        return _vm.stopDrag($event)
                      },
                      mousemove: function ($event) {
                        return _vm.doDrag($event)
                      },
                      touchmove: function ($event) {
                        return _vm.setNewCurrentPosition($event, true)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "player__slider",
                        attrs: {
                          role: "slider",
                          "aria-valuemin": "0",
                          "aria-valuenow": _vm.currentPosition,
                          "aria-valuemax": _vm.trackTotalDuration,
                          "aria-orientation": "horizontal",
                        },
                      },
                      [
                        _c("div", {
                          staticClass: "player__currentPosition",
                          style: _vm.playerBarStyling,
                        }),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "player__timeDisplay" }, [
                  _vm._v(_vm._s(_vm._f("time")(_vm.beat.length))),
                ]),
              ]
            ),
          ]),
          _c("ul", { staticClass: "player__beatActions" }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }